// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brand-heart-tsx": () => import("./../../src/pages/brand-heart.tsx" /* webpackChunkName: "component---src-pages-brand-heart-tsx" */),
  "component---src-pages-brand-messaging-tsx": () => import("./../../src/pages/brand-messaging.tsx" /* webpackChunkName: "component---src-pages-brand-messaging-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-visual-identity-tsx": () => import("./../../src/pages/visual-identity.tsx" /* webpackChunkName: "component---src-pages-visual-identity-tsx" */)
}

